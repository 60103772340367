const form = () => {
    const formContainers = document.getElementsByClassName('gis-form-container');
    if (formContainers.length > 0) {
        // get input element and info message
        const inputElement = document.querySelectorAll('.cmp-form-text__text, .cmp-form-text__textarea');

        // add elements to DOM
        inputElement.forEach((element: HTMLElement, _index) => {
            let parent = element.parentNode;
            if (parent instanceof HTMLElement && parent.hasAttribute('data-cmp-infoicon-message')) {
                const toolTipInfo = parent.getAttribute('data-cmp-infoicon-message');
                element.insertAdjacentHTML('afterend', `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="_js-tooltip"><path d="M8.09 8C8.32511 7.33167 8.78915 6.76811 9.39996 6.40913C10.0108 6.05016 10.7289 5.91894 11.4272 6.03871C12.1255 6.15849 12.7588 6.52153 13.2151 7.06353C13.6713 7.60553 13.9211 8.29152 13.92 9C13.92 11 10.92 12 10.92 12M11 16H11.01M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#6B6B6B" stroke-opacity="0.75" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>`);
                element.insertAdjacentHTML('afterend', `<div class="_js-message infoicon-message">${toolTipInfo}</div>`);
            }
        });

        // select newly added elements
        const svgElement = document.querySelectorAll('._js-tooltip');
        const toolTipInfoContainer: any = document.querySelectorAll('._js-message');

        // hide all tooltips
        toolTipInfoContainer.forEach((element: HTMLElement, _index) => {
            element.style.display = 'none';
        });

        // show tooltips on mouseover
        svgElement.forEach((element: any, _index) => {
            element.addEventListener('mouseover', (event) => {
                element.parentElement.querySelector('._js-message').style.display = 'block';
            });
            element.addEventListener('mouseout', (event) => {
                element.parentElement.querySelector('._js-message').style.display = 'none';
            });
        });

        // Autofill form fields based on GET parameters
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.forEach((value, key) => {
            const formField = document.querySelector(`[name="${key}"]`) as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
            if (formField) {
                if (formField.type === 'checkbox' || formField.type === 'radio') {
                    const input = document.querySelector(`[name="${key}"][value="${value}"]`) as HTMLInputElement;
                    if (input) {
                        input.checked = true;
                    }
                } else {
                    formField.value = value;
                }
            }
        });

        // Implements E3AUDEDH-1816 as a temporary spam protection
        let formElement = document.querySelector('.gis-form-container form') as HTMLFormElement;
        let submitButton = formElement.querySelector('button[type="submit"]') as HTMLButtonElement;

        submitButton.addEventListener('click', (event) => {
            // Arbitrary "foo-bar-address" name on purpose
            const shieldElement = formElement.querySelector('[name="foo-bar-address"]') as HTMLInputElement
            const redirectElement = formElement.querySelector('[name=":redirect"]') as HTMLInputElement
            
            if(shieldElement.value === '') {
                formElement.setAttribute('action', formElement.getAttribute('data-action'))
                if (formElement.requestSubmit && submitButton) {
                    formElement.requestSubmit(submitButton);
                } else {
                    formElement.submit();
                }
            } else {
                event.stopPropagation()
                window.location.replace(redirectElement.value)
            }
        })
    }
};

export default form;
